import { createSelector } from "@reduxjs/toolkit";
import {
  CarsAutocompleteResult,
  CarsCancellationPolicyEnum,
  CarShopResponse,
  DateTime,
  ITrackingProperties,
  SelectedCarFromListProperties,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import { getViewedCarsListProperties } from "../../../availability/reducer";
import { getSelectedAccount } from "../../../rewards/reducer";
import { getExistingCarsAvailabilityRequestParameters } from "../../../search/reducer";
import { isEqual } from "lodash-es";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const getCarShopSelectedVehicle = (state: IStoreState) => {
  return state.carShop.selectedVehicle;
};

export const getExtraInfo = (state: IStoreState): CarShopResponse | null => {
  return state.carShop.extraInfo;
};

export const getCarShopCallState = (state: IStoreState) =>
  state.carShop.carExtraInfoCallState;

export const getCarShopCallError = (state: IStoreState) =>
  state.carShop.carExtraInfoCallError;

export const getSelectedVehiclePolicyCompliance = createSelector(
  getCarShopSelectedVehicle,
  (selectedVehicle) =>
    selectedVehicle && "corporateTravel" in selectedVehicle
      ? selectedVehicle.corporateTravel.policyCompliance
      : undefined
);

export const getSelectedCarFromListProperties = createSelector(
  getViewedCarsListProperties,
  getCarShopSelectedVehicle,
  getSelectedVehiclePolicyCompliance,
  (
    viewedCarsListProperties,
    selectedVehicle,
    selectedVehiclePolicyCompliance
  ): ITrackingProperties<SelectedCarFromListProperties> => {
    const corpEvents = {
      ...(isCorpTenant(config.TENANT) && {
        in_policy: selectedVehiclePolicyCompliance?.isInPolicy ?? true,
      }),
    };
    return {
      properties: {
        ...viewedCarsListProperties.properties,
        vehicle_type: selectedVehicle?.vehicle.kind || "",
        total_price: selectedVehicle?.payNow.fiat.value || 0,
        free_cancellation:
          selectedVehicle?.cancellationPolicy.CancellationPolicy ===
          CarsCancellationPolicyEnum.FreeCancellation,
        unlimited_mileage: !!selectedVehicle?.hasUnlimitedMileage,
        automatic: !!selectedVehicle?.vehicle.transmissionType,
        supplier: selectedVehicle?.supplierRef || "",
        vendor: selectedVehicle?.vendorRef || "",
        conditional_cancellation:
          selectedVehicle?.cancellationPolicy.CancellationPolicy ===
          CarsCancellationPolicyEnum.Conditional,
        conditional_cancellation_after_booking:
          selectedVehicle?.cancellationPolicy.CancellationPolicy ===
          CarsCancellationPolicyEnum.ConditionalAfterBooking,
        non_refundable:
          selectedVehicle?.cancellationPolicy.CancellationPolicy ===
          CarsCancellationPolicyEnum.NonRefundable,
        ...corpEvents,
      },
      encryptedProperties: [...viewedCarsListProperties.encryptedProperties],
    };
  }
);

export const getViewedCarDetailsProperties = createSelector(
  getSelectedCarFromListProperties,
  getSelectedAccount,
  getCarShopSelectedVehicle,
  (
    selectedCarFromListProperties,
    account,
    carShopSelectedVehicle
  ): ITrackingProperties => {
    return {
      properties: {
        ...selectedCarFromListProperties.properties,
        account_type_selected: account?.productDisplayName || "",
        fuel_policy: carShopSelectedVehicle?.fuelPolicy?.kind || "",
        ...carShopSelectedVehicle?.offer?.trackingPropertiesV2?.properties,
        has_offer: !!carShopSelectedVehicle?.offer,
        account_use_type: account?.accountUseType,
        customer_account_role: account?.customerAccountRole,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        pax_count: carShopSelectedVehicle?.vehicle.passengerCapacity,
        bags_count: carShopSelectedVehicle?.vehicle.bagCapacity
          ? Object.values(carShopSelectedVehicle?.vehicle.bagCapacity)[0]
          : undefined,
        pickup_location: carShopSelectedVehicle?.pickUp.address,
        car_type: carShopSelectedVehicle?.vehicle.kind,
        same_dropoff: isEqual(
          carShopSelectedVehicle?.pickUp.address,
          carShopSelectedVehicle?.dropOff.address
        ),
      },
      encryptedProperties: [
        ...selectedCarFromListProperties.encryptedProperties,
        carShopSelectedVehicle?.offer?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getExistingCarsShopRequestParameters = createSelector(
  getExistingCarsAvailabilityRequestParameters,
  getCarShopSelectedVehicle,
  (
    params,
    selectedVehicle
  ): {
    dropOffDate: Date | null;
    dropOffTime: DateTime | null;
    dropOffLocation: CarsAutocompleteResult | null;
    pickUpDate: Date | null;
    pickUpTime: DateTime | null;
    pickUpLocation: CarsAutocompleteResult | null;
    driverAge: number | null;
    vehicleId: string | null;
  } => ({
    vehicleId: selectedVehicle?.id || null,
    dropOffDate: params.dropOffDate,
    dropOffTime: params.dropOffTime,
    dropOffLocation: params.dropOffLocation,
    pickUpDate: params.pickUpDate,
    pickUpTime: params.pickUpTime,
    pickUpLocation: params.pickUpLocation,
    driverAge: params.driverAge,
  })
);
