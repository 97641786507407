import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { schedulePayment } from "../../../../api/v1/book/book-flow/schedulePayment";
import {
  ConnectionResultEnum,
  InvalidEnum,
  PaymentSessionToken,
} from "redmond";
import {
  getOpaquePayments,
  getPaymentRequestV2,
  getSession,
  getUseV1PurchaseFlow,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { schedulePaymentV1 } from "../../../../api/v2/book/book-flow/schedulePayment";

export function* scheduleBookSaga() {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    const version = getUseV1PurchaseFlow(state) ? "v1" : "v0";

    if (!session) {
      throw Error("Session is undefined");
    }
    if (version === "v0") {
      yield schedulePayment(getPaymentRequestV2(state)!);
      yield put(actions.pollConfirmationDetails(session!));
    } else {
      const payments = getOpaquePayments(state);
      if (!payments) {
        throw new Error("Invalid Request.");
      }
      const result: String = yield schedulePaymentV1({
        token: session,
        payments,
      });
      yield put(
        actions.setv1FulfillSession({ value: result } as PaymentSessionToken)
      );

      yield put(
        actions.pollConfirmationDetails({
          value: result,
        } as PaymentSessionToken)
      );
    }

    yield put(actions.setScheduleBookSuccess());
  } catch (e) {
    yield put(
      actions.setScheduleBookFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
