import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { CallState } from "redmond";
import { IStoreState } from "../../../../../reducers/types";
import {
  schedulePriceQuote,
  setContactInfo,
  scheduleBook,
  setHertzLoyaltyRewardsNumber,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  setSubmitForApproval,
  fetchApplicableTravelWalletItems,
} from "../../../actions/actions";
import { CorpMobileCarBookWorkflow } from "./component";
import {
  getSelectedDriverAge,
  getIsBookingValid,
  getPollPriceQuoteCallState,
  getPriceQuote,
  getSelectedPaymentMethodId,
  getSession,
  getUserSelectedTravelerId,
  hasNoUserPassengersSelector,
  getConfirmationDetails,
  getContactPhone,
  getConfirmationEmail,
  getPriceDifferenceAcknowledged,
  getSchedulePriceQuoteCallState,
  getPaymentRequestV2,
  getHertzLoyaltyRewardsNumber,
  getIsHertzProvider,
  getPriceQuoteErrors,
  getConfirmationDetailsError,
  getOffers,
  getErrorTitles,
  getRewardsPaymentAccountReferenceId,
  getSelectedDriver,
  getCarAvailabilityRequest,
  getUseV1PurchaseFlow,
  getCredit,
  getIsTravelWalletPaymentOnly,
} from "../../../reducer";
import { getCarShopSelectedVehicle } from "../../../../shop/reducer";
import { ICorpCarAvailabilityLineItem } from "../../../../availability/reducer/utils/carAvailabilityHelperFunctions";
import { fetchTravelWalletCreditHistory } from "../../../../travel-wallet/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  priceQuote: getPriceQuote(state),
  priceQuoteInProgress:
    getSchedulePriceQuoteCallState(state) === CallState.InProcess ||
    getPollPriceQuoteCallState(state) === CallState.InProcess,
  currentSession: getSession(state),
  paymentMethodId: getSelectedPaymentMethodId(state),
  selectedTravelerId: getUserSelectedTravelerId(state),
  selectedVehicle: getCarShopSelectedVehicle(
    state
  ) as ICorpCarAvailabilityLineItem | null,
  isBookingValid: getIsBookingValid(state),
  hasNoUserPassengers: hasNoUserPassengersSelector(state),
  reservation: getConfirmationDetails(state),
  confirmationEmail: getConfirmationEmail(state),
  confirmationPhoneNumber: getContactPhone(state),
  priceDifferenceAcknowledged: getPriceDifferenceAcknowledged(state),
  driverAge: getSelectedDriverAge(state),
  canBook: !!getPriceQuote(state) && getPaymentRequestV2(state),
  hertzLoyaltyRewardsNumber: getHertzLoyaltyRewardsNumber(state),
  isHertzProvider: getIsHertzProvider(state),
  priceQuoteErrors: getPriceQuoteErrors(state),
  confirmationDetailsErrors: getConfirmationDetailsError(state),
  offers: getOffers(state),
  errorTitles: getErrorTitles(state),
  rewardsPaymentAccountReferenceId: getRewardsPaymentAccountReferenceId(state),
  driver: getSelectedDriver(state),
  paymentInfo: getPaymentRequestV2(state),
  carAvailabilityRequest: getCarAvailabilityRequest(state),
  useV1PurchaseFlow: getUseV1PurchaseFlow(state),
  credit: getCredit(state),
  isTravelWalletPaymentOnly: getIsTravelWalletPaymentOnly(state),
});

const mapDispatchToProps = {
  schedulePriceQuote,
  setContactInfo,
  scheduleBook,
  setHertzLoyaltyRewardsNumber,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  setSubmitForApproval,
  fetchApplicableTravelWalletItems,
  fetchTravelWalletCreditHistory,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCarBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCarBookWorkflow = withRouter(
  connector(CorpMobileCarBookWorkflow)
);
