import React, { useContext, useState } from "react";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import { PATH_SHOP, PATH_AVAILABILITY } from "../../../../utils/paths";
import { RouteComponentProps } from "react-router";
import { BookingErrorModalConnectorProps } from "./container";
import "./styles.scss";
import {
  CHOOSE_ANOTHER_CAR,
  TRY_AGAIN,
  CONTINUE,
  SEARCH_AGAIN,
  ERROR_ICON,
  UNABLED_ICON,
  PRICE_DECREASE_ICON,
  PRICE_INCREASE_ICON,
  UPDATE_PAYMENT_INFO,
  EDIT_REWARDS_NUMBER,
  EDIT_DRIVER,
  EDIT_PAYMENT_INFO,
} from "../../reducer/selectors/textConstants";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedShopQuery,
} from "../../../availability/utils/queryStringHelpers";
import { AGENT_FEE } from "../index";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import {
  ModalScreens,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalButtonType,
  ModalCategoryType,
  ModalAlertProperties,
} from "redmond";

export interface IBookingErrorModalProps
  extends BookingErrorModalConnectorProps,
    RouteComponentProps {}

export const BookingErrorModal = ({
  hasError,
  errorTitles,
  history,
  selectedVehicle,
  carQueryParams,
  isBookingValid,
  priceQuoteSuccessful,
  acknowledgePriceDifference,
  scheduleBook,
  redoSearch,
  resetBookErrors,
  modalType,
}: IBookingErrorModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;
  const {
    agentSubtitle,
    agentTitle,
    icon,
    primaryButtonText,
    secondaryButtonText,
    subtitle,
    title,
  } = errorTitles;
  const modalEventProperties: ModalAlertProperties = {
    type: modalType,
    screen: ModalScreens.CARS_CHECKOUT,
    primary_button: primaryButtonText!,
    secondary_button: secondaryButtonText!,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: subtitle!,
    modal_title: title!,
    agent_title: agentTitle!,
    agent_subtitle: agentSubtitle!,
    funnel: "cars",
    step: "checkout",
  };

  React.useEffect(() => {
    setIsOpen(hasError);

    if (hasError)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: modalEventProperties,
      });
  }, [hasError]);

  const handleButtonClick = (actionKey: string, isPrimary = false) => {
    setIsOpen(false);
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...modalEventProperties,
        button_choice: isPrimary
          ? ModalButtonType.PRIMARY
          : ModalButtonType.SECONDARY,
      },
    });

    switch (actionKey) {
      case SEARCH_AGAIN:
        redirectToShop();
        break;
      case TRY_AGAIN:
        if (isBookingValid && priceQuoteSuccessful) {
          resetBookErrors();
          scheduleBook(isAgentPortal ? AGENT_FEE : 0);
        } else {
          redirectToShop();
        }
        break;
      case CHOOSE_ANOTHER_CAR:
        redirectToAvailability();
        break;
      case UPDATE_PAYMENT_INFO:
      case CONTINUE:
        acknowledgePriceDifference();
        break;
      case EDIT_REWARDS_NUMBER:
        resetBookErrors();
        focusRewardsField();
        break;
      case EDIT_DRIVER:
        resetBookErrors();
        scrollToTravelers();
        break;
      case EDIT_PAYMENT_INFO:
        resetBookErrors(true);
        break;
      default:
        break;
    }
  };

  const redirectToShop = () => {
    redoSearch();
    history.push(
      `${PATH_SHOP}${transformToStringifiedShopQuery({
        vehicleId: selectedVehicle!.id!,
        ...carQueryParams,
      })}`
    );
  };

  const redirectToAvailability = () => {
    redoSearch();
    const search = transformToStringifiedAvailabilityQuery(
      carQueryParams.dropOffDate!,
      carQueryParams.dropOffTime!,
      carQueryParams.dropOffLocation!,
      carQueryParams.pickUpDate!,
      carQueryParams.pickUpTime!,
      carQueryParams.pickUpLocation!,
      carQueryParams.driverAge!
    );
    history.push(`${PATH_AVAILABILITY}${search}`);
  };

  const focusRewardsField = () => {
    setTimeout(() => {
      document.getElementById("hertz-rewards-input-field")?.focus();
    }, 0);
  };

  const scrollToTravelers = () => {
    if (!matchesMobile) {
      const travelerSectionElement = document.querySelector(
        ".traveler-select-workflow-container"
      );

      const BANNER_HEIGHT =
        document
          .getElementById("b2bportal-banner-container")
          ?.getBoundingClientRect().height ?? 0;
      const HEADER_HEIGHT =
        document.querySelector(".app-header")?.getBoundingClientRect().height ??
        0;
      const MARGIN = 20;

      const yOffset =
        window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);

      const y =
        (travelerSectionElement?.getBoundingClientRect().top ?? 0) + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  // buttons intentionally saved in reverse order for rendering
  const buttons = [
    {
      buttonText: secondaryButtonText || SEARCH_AGAIN,
      defaultStyle: "h4r-secondary",
      onClick: () => handleButtonClick(secondaryButtonText || SEARCH_AGAIN),
    },
    {
      buttonText: primaryButtonText || CHOOSE_ANOTHER_CAR,
      defaultStyle: "h4r-primary",
      onClick: () =>
        handleButtonClick(primaryButtonText || CHOOSE_ANOTHER_CAR, true),
    },
  ];

  const getIconImage = (type: string) => {
    const iconMap = {
      [ERROR_ICON]: <Icon className="error-icon" name={IconName.ErrorState} />,
      [UNABLED_ICON]: (
        <Icon className="error-icon" name={IconName.UnableToProcess} />
      ),
      [PRICE_DECREASE_ICON]: (
        <Icon className="error-icon" name={IconName.ModalPriceDrop} />
      ),
      [PRICE_INCREASE_ICON]: (
        <Icon className="error-icon" name={IconName.PriceIncrease} />
      ),
    };

    return iconMap[type] || iconMap[ERROR_ICON];
  };

  return (
    <GenericInfoPopup
      agentSubtitle={agentSubtitle}
      agentTitle={agentTitle}
      buttons={buttons}
      className="booking-error-modal"
      open={isOpen}
      image={getIconImage(icon as string)}
      isAgent={isAgentPortal}
      isMobile={matchesMobile}
      subtitle={
        <span dangerouslySetInnerHTML={{ __html: subtitle || "" }}></span>
      }
      title={title}
      // note: mobile popovers in the checkout workflow have z-index: 1300
      zIndex={matchesMobile ? 1301 : 1300}
    />
  );
};
