export const apiVersionPrefix: string = "/api/v0";

export const fetchUserBusinessAuthorizedPath: string = `${apiVersionPrefix}/businessAuthorized`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;
export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;

export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;
export const takeoversApiPrefix: string = `${apiVersionPrefix}/takeovers`;

export const cityNameApiPrefix: string = `${apiVersionPrefix}/cityName`;

export const userApiPrefix = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix = `${userApiPrefix}/wallet`;

export const lodgingLocationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/lodging`;
export const flightItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/flight`;
export const hotelItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/hotel`;

export const recentlySearchedApiPrefix: string = `${apiVersionPrefix}/productFeed/recentlySearched`;
export const recentlyViewedApiPrefix: string = `${apiVersionPrefix}/productFeed/recentlyViewed`;
export const locationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/lodging`;
export const hotelAvailabilityApiPrefix: string = `${apiVersionPrefix}/hotels/availability`;

export const portalAccessPrefix = `${apiVersionPrefix}/portalAccess`;

export const crossSellActiveOffers = `${apiVersionPrefix}/crosssell/offers/active`;

export const apiVersionPrefixV2: string = "/api/v2";
const ancillariesApiPrefix: string = `${apiVersionPrefixV2}/ancillaries`;
const ancillariesPostBookingApiPrefix: string = `${ancillariesApiPrefix}/postbooking`;
export const ancillaryPostBookingOfferPath = `${ancillariesPostBookingApiPrefix}/offer/get`;

export const fetchPolicies = `${apiVersionPrefix}/policies/details`;
export const fetchUsersList = `${apiVersionPrefix}/users/list`;

export const userPreferenceApiPrefix: string = `${apiVersionPrefix}/user/preference`;
export const userHotelPreferencesPath: string = `${userPreferenceApiPrefix}/hotel`;
export const userPreferencesStatusPath: string = `${userPreferenceApiPrefix}/status`;
export const userFlightPreferencesPath: string = `${userPreferenceApiPrefix}/flight`;

export const paymentMethodsApiPrefix: string = `${apiVersionPrefix}/paymentMethods`;

//Corporate
export const userUpdateDefaultTravelerPath: string = `${apiVersionPrefix}/passengers/assign_default`;
export const userUpdateHideTravelerPath: string = `${apiVersionPrefix}/passengers/hide`;
export const userUpdateShowTravelerPath: string = `${apiVersionPrefix}/passengers/show`;

export const cotbApiPrefix: string = `${apiVersionPrefix}/cotb`;

export const changeBusinessInUsePath: string = `${cotbApiPrefix}/changeBusinessInUse`;
