import { createSelector } from "reselect";
import {
  VehicleAvailability,
  IPriceRange,
  ITrackingProperties,
  ViewedCarsListProperties,
  CarsEntryProperties,
  IdEnum,
  StatementCreditDetail,
  CarsFilterEventProperties,
} from "redmond";
import { isEqual } from "lodash-es";

import {
  initialFilterState,
  PLACEHOLDER_PRICE_RANGE,
  PLACEHOLDER_CAR_COMPANIES,
  CarAvailabilityCallState,
} from "../index";
import { IStoreState } from "../../../../reducers/types";
import {
  getSelectedAccountIndex,
  getSelectedAccount,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getIsFirstLaunch,
  getRewardsAccounts,
  getAgentEmail,
} from "../../../rewards/reducer";
import {
  getDriverAge,
  getDropOffDate,
  getDropOffLocation,
  getDropOffTime,
  getPickUpDate,
  getPickUpLocation,
  getPickUpTime,
  getExistingCarsAvailabilityRequestParameters,
  DEFAULT_DRIVER_AGE,
} from "../../../search/reducer";
import {
  ICarAvailabilityLineItem,
  getCarAvailabilityLineItem,
  getVehiclePricePerDay,
} from "../utils/carAvailabilityHelperFunctions";
import {
  performCarTypeFilter,
  performPriceRangeFilter,
  performCarCompaniesFilter,
  performSpecificationsFilter,
  performCancellationPolicyFilter,
  performMaxTotalPriceFilter,
  performPassengersFilter,
  performPickUpTypeFilter,
  performBagsFilter,
  performPolicyFilter,
} from "../utils/processFilters";
import dayjs from "dayjs";
import {
  getCreditBreakdown,
  getTravelWalletCredit,
} from "../../../travel-wallet/reducer";

export const getCarAvailabilityVehicles = (state: IStoreState) =>
  state.carAvailability.vehicles;

export const getCarAvailabilityContext = (state: IStoreState) =>
  state.carAvailability.context;

export const getCarAvailabilityBestOfferOverall = (state: IStoreState) =>
  state.carAvailability.bestOfferOverall;

export const getCarAvailabilityNextPageToken = (state: IStoreState) =>
  state.carAvailability.nextPageToken;

export const getCarAvailabilityCallState = (state: IStoreState) =>
  state.carAvailability.carAvailabilityCallState;

export const getCarAvailabilityCarTypeFilter = (state: IStoreState) =>
  state.carAvailability.carTypes;

export const getCarAvailabilityMaxTotalPrice = (state: IStoreState) =>
  state.carAvailability.maxTotalPrice;

export const hasChangedCarAvailabilityMaxTotalPrice = createSelector(
  getCarAvailabilityMaxTotalPrice,
  (maxTotalPrice) => !!maxTotalPrice
);
// Corporate
export const getCarAvailabilityPolicyFilter = (state: IStoreState) =>
  state.carAvailability.isInPolicy;

export const hasChangedCarAvailabilityCarTypeFilter = createSelector(
  getCarAvailabilityCarTypeFilter,
  (carType) => !isEqual(carType, initialFilterState.carTypes)
);

export const hasChangedCarAvailabilityPolicyFilter = createSelector(
  getCarAvailabilityPolicyFilter,
  (isInPolicy) => !isEqual(isInPolicy, initialFilterState.isInPolicy)
);

const getCarAvailabilityPriceRangeFilter = (state: IStoreState) =>
  state.carAvailability.pricePerDay;

// note: this priceRange selector should be used as min/max values in the Filter component
export const getCarAvailabilityMinMaxPriceRange = createSelector(
  getCarAvailabilityVehicles,
  (vehicles): IPriceRange => {
    if (vehicles.length === 0) {
      return PLACEHOLDER_PRICE_RANGE;
    }

    let curMin = Math.floor(getVehiclePricePerDay(vehicles[0]));
    let curMax = Math.ceil(getVehiclePricePerDay(vehicles[0]));

    vehicles.forEach((vehicle) => {
      const curPrice = getVehiclePricePerDay(vehicle);

      if (!curMin || Math.floor(curPrice) < curMin) {
        curMin = Math.floor(curPrice);
      }
      if (!curMax || Math.ceil(curPrice) > curMax) {
        curMax = Math.ceil(curPrice);
      }
    });

    return {
      min: curMin,
      max: curMax,
    };
  }
);

// note: this priceRange selector should be used as the priceRange value in the Filter component
export const getNonEmptyCarAvailabilityPriceRangeFilter = createSelector(
  getCarAvailabilityPriceRangeFilter,
  getCarAvailabilityMinMaxPriceRange,
  (priceRange, minMaxPriceRange): IPriceRange => priceRange || minMaxPriceRange
);

export const hasChangedCarAvailabilityPriceRangeFilter = createSelector(
  getNonEmptyCarAvailabilityPriceRangeFilter,
  getCarAvailabilityMinMaxPriceRange,
  (priceRange, minMaxPriceRange) =>
    // when minMaxPriceRange is null, it means either vehicles aren't set or it's empty result; treat that as a false
    !!minMaxPriceRange && !isEqual(priceRange, minMaxPriceRange)
);

export const getCarAvailabilityTotalMinMaxPriceRange = createSelector(
  getCarAvailabilityVehicles,
  (vehicles): IPriceRange => {
    if (vehicles.length === 0) {
      return PLACEHOLDER_PRICE_RANGE;
    }

    let curMin = Math.floor(vehicles[0]?.payNow.fiat.value);
    let curMax = Math.ceil(vehicles[0]?.payNow.fiat.value);

    vehicles.forEach((vehicle) => {
      const curPrice = vehicle.payNow.fiat.value;

      if (!curMin || Math.floor(curPrice) < curMin) {
        curMin = Math.floor(curPrice);
      }
      if (!curMax || Math.ceil(curPrice) > curMax) {
        curMax = Math.ceil(curPrice);
      }
    });

    return {
      min: curMin,
      max: curMax,
    };
  }
);

export const getCarAvailabilityCarCompaniesFilter = (state: IStoreState) =>
  state.carAvailability.carCompanies;

// note: this companies list selector should be used as the options value in the Filter component
export const getCarAvailabilityCarCompaniesList = createSelector(
  getCarAvailabilityContext,
  (context): { key: string; name: string }[] => {
    if (isEqual(context.suppliers, {})) {
      return PLACEHOLDER_CAR_COMPANIES;
    }

    const result = Object.entries(context.suppliers)
      .map(([key, supplier]) => ({
        key,
        name: supplier.name,
      }))
      .sort((a, b) => {
        if (a.name > b.name) {
          return -1;
        }
        if (b.name > a.name) {
          return 1;
        }
        return 0;
      });

    return result;
  }
);

export const hasChangedCarAvailabilityCarCompaniesFilter = createSelector(
  getCarAvailabilityCarCompaniesFilter,
  getCarAvailabilityContext,
  (carCompanies, context) => {
    let hasChanged = false;

    Object.entries(carCompanies).forEach(([code, isCodeSelected]) => {
      if (context.suppliers[code] && isCodeSelected) {
        hasChanged = true;
      }
    });

    return hasChanged;
  }
);

export const getCarAvailabilityPassengersFilter = (state: IStoreState) =>
  state.carAvailability.passengers;

export const hasChangedCarAvailabilityPassengersFilter = createSelector(
  getCarAvailabilityPassengersFilter,
  (passengers) => !isEqual(passengers, initialFilterState.passengers)
);

export const getCarAvailabilitySpecificationsFilter = (state: IStoreState) =>
  state.carAvailability.specifications;

export const hasChangedCarAvailabilitySpecificationsFilter = createSelector(
  getCarAvailabilitySpecificationsFilter,
  (specifications) =>
    !isEqual(specifications, initialFilterState.specifications)
);

export const getCarAvailabilityCancellationPolicyFilter = (
  state: IStoreState
) => state.carAvailability.cancellation;

export const hasChangedCarAvailabilityCancellationPolicyFilter = createSelector(
  getCarAvailabilityCancellationPolicyFilter,
  (cancellation) => !isEqual(cancellation, initialFilterState.cancellation)
);

export const getCarAvailabilityPickUpFilter = (state: IStoreState) =>
  state.carAvailability.pickUp;

export const hasChangedCarAvailabilityPickUpFilter = createSelector(
  getCarAvailabilityPickUpFilter,
  (pickUp) => !isEqual(pickUp, initialFilterState.pickUp)
);

export const getCarAvailabilityBagsFilter = (state: IStoreState) =>
  state.carAvailability.bags;

export const hasChangedCarAvailabilityBagsFilter = createSelector(
  getCarAvailabilityBagsFilter,
  (bags) => !isEqual(bags, initialFilterState.bags)
);

export const getCarAvailabityMinimumBags = createSelector(
  getCarAvailabilityBagsFilter,
  (bags) => {
    return bags.length > 0 ? Math.min(...bags) : 0;
  }
);

const getAllCarAvailabilityFilters = createSelector(
  getCarAvailabilityCarTypeFilter,
  getCarAvailabilityPriceRangeFilter,
  getCarAvailabilityMaxTotalPrice,
  getCarAvailabilityCarCompaniesFilter,
  getCarAvailabilitySpecificationsFilter,
  getCarAvailabilityCancellationPolicyFilter,
  getCarAvailabilityPassengersFilter,
  getCarAvailabilityPickUpFilter,
  getCarAvailabilityBagsFilter,
  getCarAvailabilityPolicyFilter,
  (
    carType,
    priceRange,
    maxTotalPrice,
    carCompanies,
    specifications,
    cancellation,
    passengers,
    pickUp,
    bags,
    isInPolicy
  ) => ({
    carType,
    priceRange,
    maxTotalPrice,
    carCompanies,
    specifications,
    cancellation,
    passengers,
    pickUp,
    bags,
    isInPolicy,
  })
);

export const getCarFilterEventProperties = createSelector(
  getAllCarAvailabilityFilters,
  (carAvailabilityFilters): CarsFilterEventProperties => {
    return {
      car_type: carAvailabilityFilters.carType.toString(),
      passengers: carAvailabilityFilters.passengers.toString(),
      bags: carAvailabilityFilters.bags.toString(),
      pickup_location: carAvailabilityFilters.pickUp.toString(),
      rental_company: carAvailabilityFilters.carCompanies.toString(),
      price: carAvailabilityFilters.maxTotalPrice,
      cancellation: carAvailabilityFilters.cancellation["FreeCancellation"]
        ? "FreeCancellation"
        : undefined,
    };
  }
);

export const getIsCarsCXV1Experiment = (state: IStoreState) =>
  state.carAvailability.isCarsCXV1Experiment;

export const getFilteredCarAvailabilityVehicles = createSelector(
  getAllCarAvailabilityFilters,
  getCarAvailabityMinimumBags,
  getCarAvailabilityVehicles,
  hasChangedCarAvailabilityCarCompaniesFilter,
  getIsCarsCXV1Experiment,
  (
    filters,
    minimumBags,
    vehicles,
    hasChangedCarCompaniesFilter,
    isCarsCXV1Experiment
  ): VehicleAvailability[] => {
    const {
      carType,
      priceRange,
      maxTotalPrice,
      carCompanies,
      specifications,
      cancellation,
      passengers,
      pickUp,
      isInPolicy,
    } = filters;

    const meetsFilterPredicates = (vehicle: VehicleAvailability) => {
      if (
        !performCarTypeFilter(vehicle, carType) ||
        !performPriceRangeFilter(vehicle, priceRange) ||
        !performMaxTotalPriceFilter(vehicle, maxTotalPrice) ||
        !performCarCompaniesFilter(
          vehicle,
          carCompanies,
          hasChangedCarCompaniesFilter
        ) ||
        !performSpecificationsFilter(vehicle, specifications) ||
        !performPassengersFilter(vehicle, passengers) ||
        !performCancellationPolicyFilter(
          vehicle,
          cancellation,
          isCarsCXV1Experiment
        ) ||
        !performPickUpTypeFilter(vehicle, pickUp) ||
        !performBagsFilter(vehicle, minimumBags) ||
        !performPolicyFilter(vehicle, isInPolicy)
      ) {
        return false;
      }
      return true;
    };

    return vehicles.filter(meetsFilterPredicates);
  }
);

export const getOpenDatesModal = (state: IStoreState) =>
  state.carAvailability.openDatesModal;

export const getCarAvailabilityLineItems = createSelector(
  getFilteredCarAvailabilityVehicles,
  getPickUpLocation,
  getCarAvailabilityContext,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  (vehicles, pickUpLocation, context, rewardsKey): ICarAvailabilityLineItem[] =>
    vehicles.map((vehicle, index) => {
      const locationLabel =
        pickUpLocation?.id.Id === IdEnum.Flight
          ? `${pickUpLocation.label}`
          : `to pick-up location`;
      return getCarAvailabilityLineItem(
        vehicle,
        context,
        rewardsKey,
        locationLabel,
        index
      );
    })
);
export const getCarShopQueryParams = createSelector(
  getDropOffDate,
  getPickUpDate,
  getDropOffLocation,
  getPickUpLocation,
  getDriverAge,
  getDropOffTime,
  getPickUpTime,
  getSelectedAccountIndex,
  (
    dropOffDate,
    pickUpDate,
    dropOffLocation,
    pickUpLocation,
    driverAge,
    dropOffTime,
    pickUpTime,
    selectedAccountIndex
  ) => ({
    dropOffDate,
    dropOffLocation,
    pickUpDate,
    pickUpLocation,
    driverAge,
    dropOffTime,
    pickUpTime,
    selectedAccountIndex,
  })
);

const DEFAULT_CURRENCY_SYMBOL = "$";

export const getCurrencySymbol = createSelector(
  getCarAvailabilityVehicles,
  (vehicles): string =>
    vehicles.length > 0
      ? vehicles[0].payNow.fiat.currencySymbol
      : DEFAULT_CURRENCY_SYMBOL
);

export const getDropOffLocationSearched = (state: IStoreState) =>
  state.carAvailability.dropOffLocationSearched;

export const getPickUpLocationSearched = (state: IStoreState) =>
  state.carAvailability.pickUpLocationSearched;

export const getDropOffDateSearched = (state: IStoreState) =>
  state.carAvailability.dropOffDateSearched;

export const getPickUpDateSearched = (state: IStoreState) =>
  state.carAvailability.pickUpDateSearched;

export const getDropOffTimeSearched = (state: IStoreState) =>
  state.carAvailability.dropOffTimeSearched;

export const getPickUpTimeSearched = (state: IStoreState) =>
  state.carAvailability.pickUpTimeSearched;

export const getCarAvailabilityPickUpLocationCountry = (state: IStoreState) =>
  state.carAvailability.pickUpLocationCountry;

export const areRequestParametersChanged = createSelector(
  getExistingCarsAvailabilityRequestParameters,
  getDropOffLocationSearched,
  getPickUpLocationSearched,
  getDropOffDateSearched,
  getPickUpDateSearched,
  getDropOffTimeSearched,
  getPickUpTimeSearched,
  (
    requestParameters,
    dropOffLocationSearched,
    pickUpLocationSearched,
    dropOffDateSearched,
    pickUpDateSearched,
    dropOffTimeSearched,
    pickUpTimeSearched
  ): boolean =>
    !isEqual(dropOffLocationSearched, requestParameters.dropOffLocation) ||
    !isEqual(pickUpLocationSearched, requestParameters.pickUpLocation) ||
    !isEqual(dropOffDateSearched, requestParameters.dropOffDate) ||
    !isEqual(pickUpDateSearched, requestParameters.pickUpDate) ||
    !isEqual(dropOffTimeSearched, requestParameters.dropOffTime) ||
    !isEqual(pickUpTimeSearched, requestParameters.pickUpTime)
);

export const getVehicleResultsBySupplier = createSelector(
  getCarAvailabilityVehicles,
  (
    vehicles
  ): {
    results_from_supplier_ABG?: number;
    results_from_supplier_AM?: number;
    results_from_supplier_HE?: number;
    results_from_supplier_PL?: number;
    results_from_supplier_SX?: number;
  } =>
    vehicles.reduce((result, vehicle) => {
      let numResults = 1;
      if (result[`results_from_supplier_${vehicle.vendorRef}`])
        numResults += result[`results_from_supplier_${vehicle.vendorRef}`];
      return {
        ...result,
        [`results_from_supplier_${vehicle.vendorRef}`]: numResults,
      };
    }, {})
);

export const getCarAvailabilityEntryPoint = (state: IStoreState) =>
  state.carAvailability.carAvailabilityEntryPoint;

export const getPaymentMethods = (state: IStoreState) =>
  state.carAvailability.paymentMethods;

export const getCarsEntryProperties = createSelector(
  getAgentEmail,
  getPickUpDate,
  getDropOffDate,
  getPickUpTime,
  getDropOffTime,
  getDriverAge,
  getDropOffLocation,
  getPickUpLocation,
  getIsFirstLaunch,
  getRewardsAccounts,
  getTravelWalletCredit,
  getCreditBreakdown,
  getSelectedAccount,
  getCarAvailabilityEntryPoint,
  getPaymentMethods,
  (
    agentEmail,
    pickUpDate,
    dropOffDate,
    pickUpTime,
    dropOffTime,
    driverAge,
    dropOffLocation,
    pickUpLocation,
    isFirstLaunch,
    rewardsAccounts,
    credit,
    creditBreakdown,
    selectedAccount,
    carAvailabilityEntryPoint,
    paymentMethods
  ): ITrackingProperties<CarsEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        pick_up_date: dayjs(pickUpDate).format("MM/DD/YYYY"),
        drop_off_date: dayjs(dropOffDate).format("MM/DD/YYYY"),
        pick_up_hour: pickUpTime ? parseInt(dayjs(pickUpTime).format("H")) : 0,
        drop_off_hour: dropOffTime
          ? parseInt(dayjs(dropOffTime).format("H"))
          : 0,
        cars_advance: dayjs(pickUpDate).diff(dayjs(), "day"),
        same_pickUp_dropOff: pickUpLocation?.id === dropOffLocation?.id,
        search_age: driverAge || DEFAULT_DRIVER_AGE,
        market: dropOffLocation?.label || "",
        country: dropOffLocation?.label || "",
        airport: dropOffLocation?.label || "",
        rewards_accounts: rewardsAccounts
          .map((r) => r.productDisplayName)
          .join(","),
        ...dropOffLocation?.trackingPropertiesV2?.properties,
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        customer_account_role: selectedAccount?.customerAccountRole,
        entry_type: carAvailabilityEntryPoint,
        card_on_file: paymentMethods?.length > 0,
      },
      encryptedProperties: [
        dropOffLocation?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getViewedCarsListProperties = createSelector(
  getCarsEntryProperties,
  getSelectedAccount,
  getCarAvailabilityVehicles,
  getPickUpLocation,
  getDropOffLocation,
  getCarAvailabilityBestOfferOverall,
  getVehicleResultsBySupplier,
  getCarAvailabilityPickUpLocationCountry,
  (
    carEntryProperties,
    account,
    vehicles,
    pickUpLocation,
    dropOffLocation,
    bestOfferOverall,
    vehicleResultsPerSupplier,
    pickUpLocationCountry
  ): ITrackingProperties<ViewedCarsListProperties> => ({
    properties: {
      account_type_selected: account?.productDisplayName || "",
      number_of_results: vehicles.length || 0,
      ...vehicleResultsPerSupplier,
      cheapest_price: 0, // TODO we need this info from BE
      cheapest_provider: "", // TODO need BE info
      pick_up_place_country:
        pickUpLocationCountry ?? (pickUpLocation?.label || ""),
      pick_up_place_city: pickUpLocation?.label || "",
      pick_up_place_id: "",
      drop_off_place_country: dropOffLocation?.label || "",
      drop_off_place_city: dropOffLocation?.label || "",
      drop_off_place_id: "",
      ...dropOffLocation?.trackingPropertiesV2?.properties,
      ...pickUpLocation?.trackingPropertiesV2?.properties,
      ...bestOfferOverall?.trackingPropertiesV2?.properties,
      ...carEntryProperties.properties,
      has_offer: !!bestOfferOverall,
      account_use_type: account?.accountUseType,
      customer_account_role: account?.customerAccountRole,
      account_allow_rewards_redemption: account?.allowRewardsRedemption,
    },
    encryptedProperties: [
      ...carEntryProperties.encryptedProperties,
      pickUpLocation?.trackingPropertiesV2?.encryptedProperties ?? "",
      dropOffLocation?.trackingPropertiesV2?.encryptedProperties ?? "",
      bestOfferOverall?.trackingPropertiesV2?.encryptedProperties ?? "",
    ],
  })
);

export const hasChangedCarAvailabilityFilter = createSelector(
  hasChangedCarAvailabilityCarTypeFilter,
  hasChangedCarAvailabilityPriceRangeFilter,
  hasChangedCarAvailabilityCarCompaniesFilter,
  hasChangedCarAvailabilitySpecificationsFilter,
  hasChangedCarAvailabilityCancellationPolicyFilter,
  hasChangedCarAvailabilityPassengersFilter,
  hasChangedCarAvailabilityPickUpFilter,
  hasChangedCarAvailabilityBagsFilter,
  (
    hasChangedCarType,
    hasChangedPriceRange,
    hasChangedCarCompanies,
    hasChangedSpecifications,
    hasChangedCancellationPolicy,
    hasChangedPassengers,
    hasChangedPickUp,
    hasChangedBags
  ): boolean =>
    hasChangedCarType ||
    hasChangedPriceRange ||
    hasChangedCarCompanies ||
    hasChangedSpecifications ||
    hasChangedCancellationPolicy ||
    hasChangedPassengers ||
    hasChangedPickUp ||
    hasChangedBags
);

export const isCarAvailabilityInEmptyState = createSelector(
  getFilteredCarAvailabilityVehicles,
  getCarAvailabilityCallState,
  (vehicles, callState): boolean =>
    vehicles.length === 0 && callState === CarAvailabilityCallState.Complete
);

export const getAppliedFiltersCount = createSelector(
  hasChangedCarAvailabilityCarTypeFilter,
  hasChangedCarAvailabilityPriceRangeFilter,
  hasChangedCarAvailabilityMaxTotalPrice,
  hasChangedCarAvailabilityCarCompaniesFilter,
  hasChangedCarAvailabilitySpecificationsFilter,
  hasChangedCarAvailabilityCancellationPolicyFilter,
  hasChangedCarAvailabilityPassengersFilter,
  hasChangedCarAvailabilityPickUpFilter,
  hasChangedCarAvailabilityBagsFilter,
  hasChangedCarAvailabilityPolicyFilter,
  (
    hasChangedCarType,
    hasChangedPriceRange,
    hasChangedMaxTotalPrice,
    hasChangedCarCompanies,
    hasChangedSpecifications,
    hasChangedCancellationPolicy,
    hasChangedPassengers,
    hasChangedPickUp,
    hasChangedBags,
    hasChangedPolicy
  ): number =>
    [
      hasChangedCarType,
      hasChangedPriceRange,
      hasChangedMaxTotalPrice,
      hasChangedCarCompanies,
      hasChangedSpecifications,
      hasChangedCancellationPolicy,
      hasChangedPassengers,
      hasChangedPickUp,
      hasChangedBags,
      hasChangedPolicy,
    ].filter((hasChanged) => hasChanged).length
);

export const getLowestTotalPrice = createSelector(
  getCarAvailabilityVehicles,
  (vehicles): number => {
    if (vehicles.length) {
      let curMin = vehicles[0]?.payNow.fiat.value;

      vehicles.forEach((vehicle) => {
        const curPrice = vehicle.payNow.fiat.value;

        if (!curMin || curPrice < curMin) {
          curMin = curPrice;
        }
      });

      return curMin;
    }

    return 0;
  }
);
