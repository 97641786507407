import React from "react";
import clsx from "clsx";
import {
  BackButton,
  MobileFloatingButton,
  MobilePopoverCard,
  frequentFlyerRegex as rewardsRegex,
} from "halifax";
import { ITravelerStepErrors } from "redmond";
import { isCaponeTenant, isCorpTenant } from "@capone/common";

import "./styles.scss";
import { HertzLoyaltyForm } from "../HertzRewardsForm";
import { CONTINUE } from "./constants";
import { config } from "../../../../api/config";

export enum HertzLoyaltyStep {
  Main,
  HertzLoyaltyForm,
}

export interface IHertzLoyaltyWorkflowProps {
  progressStep: HertzLoyaltyStep;
  setProgressStep: (progressStep: HertzLoyaltyStep) => void;
  onGoBack?: () => void;
  onContinueClick?: () => void;
  mobileHeaderElement?: JSX.Element;
  isMobile?: boolean;
  showErrors?: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
  hertzLoyaltyRewardsNumber?: string;
}

export const HertzLoyaltyWorkflow = ({
  progressStep,
  setProgressStep,
  onGoBack,
  onContinueClick,
  mobileHeaderElement,
  showErrors,
  setShowErrors,
  hertzLoyaltyRewardsNumber,
}: IHertzLoyaltyWorkflowProps) => {
  const [openHertzLoyaltyFormModal, setHertzLoyaltyFormModal] =
    React.useState<boolean>(false);

  const setProgress = (step: HertzLoyaltyStep) => {
    setProgressStep(step);
  };

  React.useEffect(() => {
    switch (progressStep) {
      case HertzLoyaltyStep.Main:
        setHertzLoyaltyFormModal(false);
        break;
      case HertzLoyaltyStep.HertzLoyaltyForm:
        setHertzLoyaltyFormModal(true);
        break;
      default:
        break;
    }
  }, [progressStep]);

  const hasValidLoyaltyNumber = hertzLoyaltyRewardsNumber
    ? !showErrors?.loyaltyNumber && rewardsRegex.test(hertzLoyaltyRewardsNumber)
    : true;

  const renderBackButton = () => (
    <BackButton
      className="hertz-loyalty-rewards-back-button"
      onClick={() => {
        setProgress(HertzLoyaltyStep.Main);
        onGoBack && onGoBack();
      }}
    />
  );

  return (
    <MobilePopoverCard
      open={openHertzLoyaltyFormModal}
      className={clsx("hertz-loyalty-rewards-popup", "mobile", "b2b")}
      onClose={() => {
        setProgress(HertzLoyaltyStep.Main);
        onGoBack && onGoBack();
      }}
      fullScreen={isCorpTenant(config.TENANT)}
      topLeftButton={renderBackButton()}
      headerElement={mobileHeaderElement}
      fullScreenWithBanner={isCaponeTenant(config.TENANT)}
    >
      <HertzLoyaltyForm
        showErrors={showErrors}
        setShowErrors={setShowErrors}
        isMobile
      />
      {hasValidLoyaltyNumber && (
        <MobileFloatingButton
          className={clsx("mobile-hertz-loyalty-rewards-button", "b2b")}
          wrapperClassName={clsx(
            "mobile-hertz-loyalty-rewards-button-container",
            "b2b"
          )}
          onClick={() => {
            setProgress(HertzLoyaltyStep.Main);
            onContinueClick && onContinueClick();
          }}
        >
          {CONTINUE}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
