import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@material-ui/core";
import { RouterProps } from "react-router";
import {
  CorpCarSearchLoadingImage,
  CarSearchLoadingImage,
  B2BLoadingPopup,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import {
  DesktopCarsAvailability,
  MobileCarsAvailability,
  AvailabilityErrorModal,
} from "./components";
import { CarAvailabilityConnectorProps } from "./container";
import "./styles.scss";
import * as constants from "./textConstants";
import { trackEvent } from "../../api/v1/analytics/trackEvent";
import { VIEWED_CARS_LIST } from "redmond";
import { PORTAL_TITLE, SELECT_A_CAR_TITLE } from "../../lang/textConstants";
import { CarAvailabilityCallState } from "./reducer";
import {
  getExperimentVariant,
  AVAILABLE,
  useExperiments,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  RECENTLY_VIEWED_V2_CARS,
  CARS_CX_V1,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
} from "../../context/experiments";
import { config } from "../../api/config";
import { isCorpTenant } from "@capone/common";
import { trackEngagementEvent } from "../../api/v1/engagement-data/trackEngagementEvent";

export interface ICarsAvailability
  extends CarAvailabilityConnectorProps,
    RouterProps {}

export const CarsAvailability = (props: ICarsAvailability) => {
  const {
    isFetchingInitialAvailability,
    fetchInitialCarsAvailability,
    history,
    viewedCarsListProperties,
    carAvailabilityCallState,
    fetchTravelWalletDetails,
    largestValueAccount,
    fetchTravelWalletCreditHistory,
    setIsCarsCXV1Experiment,
    lowestTotalPrice,
    pickUpLocation,
    dropOffLocation,
    pickUpDate,
    dropOffDate,
    listPaymentMethods,
  } = props;

  const { matchesMobile } = useDeviceTypes();
  const [currentUrl, setCurrentUrl] = useState(history.location.search);
  const expState = useExperiments();

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.carsMultiplier &&
    !isCorpTenant(config.TENANT);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const recentlyViewedV2Cars = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_V2_CARS
  );

  const isRecentlyViewedCarsV2Experiment = useMemo(() => {
    return recentlyViewedV2Cars === AVAILABLE;
  }, [recentlyViewedV2Cars]);

  const globalMobileNav = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );

  const isGlobalMobileNavExperiment = useMemo(() => {
    return globalMobileNav === AVAILABLE;
  }, [globalMobileNav]);

  useEffect(() => {
    fetchTravelWalletDetails();
    listPaymentMethods();
    document.title = SELECT_A_CAR_TITLE;
    // TODO: Find out why window.scrollTo requires a setTimeout; it's needed so that the browser nav button works with scrollTo
    setTimeout(() => window.scrollTo(0, 0), 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (
      isGlobalMobileNavExperiment && matchesMobile
        ? carAvailabilityCallState === CarAvailabilityCallState.NotCalled
        : true
    ) {
      fetchInitialCarsAvailability(history, isRecentlyViewedCarsV2Experiment);
    }
  }, [
    isRecentlyViewedCarsV2Experiment,
    matchesMobile,
    isGlobalMobileNavExperiment,
  ]);

  useEffect(() => {
    if (history.location.search !== currentUrl) {
      fetchInitialCarsAvailability(history, isRecentlyViewedCarsV2Experiment);
      setCurrentUrl(history.location.search);
    }
  }, [history.location.search, isRecentlyViewedCarsV2Experiment]);

  useEffect(() => {
    if (carAvailabilityCallState === CarAvailabilityCallState.Complete) {
      trackEvent({
        eventName: VIEWED_CARS_LIST,
        properties: viewedCarsListProperties.properties,
        encryptedProperties: viewedCarsListProperties.encryptedProperties,
      });
    }
  }, [carAvailabilityCallState, viewedCarsListProperties]);

  useEffect(() => {
    if (isTravelCreditHistoryExperiment) {
      fetchTravelWalletCreditHistory();
    }
  }, [isTravelCreditHistoryExperiment]);

  const carsCXV1Experiment = getExperimentVariant(
    expState.experiments,
    CARS_CX_V1
  );
  const isCarsCXV1Experiment = React.useMemo(
    () => carsCXV1Experiment === AVAILABLE,
    [carsCXV1Experiment]
  );

  useEffect(() => {
    setIsCarsCXV1Experiment(isCarsCXV1Experiment);
  }, [isCarsCXV1Experiment]);

  useEffect(() => {
    if (carAvailabilityCallState === CarAvailabilityCallState.Complete) {
      trackEngagementEvent({
        event: {
          event_type: "car_search",
          lowest_price: lowestTotalPrice,
          pick_up_location: pickUpLocation?.label,
          drop_off_location: dropOffLocation?.label,
          pick_up_date_timestamp: pickUpDate?.getTime(),
          drop_off_date_timestamp: dropOffDate?.getTime(),
        },
      });
    }
  }, [carAvailabilityCallState]);

  const isCorporate = isCorpTenant(config.TENANT);

  return (
    <>
      <B2BLoadingPopup
        open={isFetchingInitialAvailability}
        message={constants.FETCHING_AVAILABILITY_TEXT}
        secondaryMessage={
          showEarnEnhancement
            ? constants.EARN_ENHANCEMENT_SUBTITLE(
                largestValueAccount.earn.carsMultiplier,
                largestValueAccount.productDisplayName,
                largestValueAccount.rewardsBalance.currencyDescription ??
                  largestValueAccount.rewardsBalance.currency
              )
            : constants.FETCHING_AVAILABILITY_SECONDARY_TEXT
        }
        image={isCorporate ? CorpCarSearchLoadingImage : CarSearchLoadingImage}
        popupSize={matchesMobile ? "mobile" : "desktop"}
        className={clsx("car-search-loading-popup", {
          "earn-enhancement": showEarnEnhancement,
        })}
        includeDivider={showEarnEnhancement}
      />
      <AvailabilityErrorModal />
      <Box
        className={clsx("car-availability-root", {
          "full-width": !matchesMobile,
        })}
      >
        <Box className="car-availability-container">
          {!matchesMobile && <DesktopCarsAvailability />}
          {matchesMobile && <MobileCarsAvailability />}
        </Box>
      </Box>
    </>
  );
};
